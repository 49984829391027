module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-jss-provider/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"4","matomoUrl":"https://stats.villarreal.co.cr","siteUrl":"https://glitchy.website"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"glitchy.website","short_name":"glitchy.website","start_url":"/","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"87e1d87f3ff25224a4d6f70cb2b5ba3d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
